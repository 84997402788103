<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="400"
  >
    <div class="bg-white poppins">
      <div class="modal-body d-flex flex-column h-220px scroll-y mx-5">
        <div class="h-75px pt-6">
          <v-text-field
            v-model="inputValue"
            :label="'Add ' + dialogType"
            autofocus
            clearable
            dense
            outlined
            @keydown.enter.prevent="submitForm"
          />
        </div>

        <div
          class="mb-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-sm btn-light mr-3 px-5 py-3 ls1"
            @click="toggleModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { SET_SCANNED_SKUS } from "@/core/services/store/receiving.module";

export default {
  name: "ToteLPNDialog",
  data: () => ({
    dialog: false,
    inputValue: null,
    checkedItems: [],
    dialogType: null,
  }),
  methods: {
    toggleModal(items, type) {
      if (this.dialog) {
        this.inputValue = null;
        this.checkedItems = [];
        this.dialogType = null;
      } else {
        if (type === "tote") this.inputValue = items[0].tote_id;
        else this.inputValue = items[0].lpn_id;
        this.checkedItems = items;
        this.dialogType = type;
      }
      this.dialog = !this.dialog;
    },
    submitForm() {
      const temp = this.$store.getters.getWMSRECEIVINGScannedSKUS;

      this.checkedItems.forEach((item) => {
        temp.forEach((tempItem) => {
          if (item.id === tempItem.id && this.dialogType === "tote") {
            tempItem.tote_id = this.inputValue;
          } else if (item.id === tempItem.id && this.dialogType === "LPN") {
            tempItem.lpn_id = this.inputValue;
          }
        });
      });

      this.$store.commit(SET_SCANNED_SKUS, temp);
      this.toggleModal();
    },
  },
};
</script>
