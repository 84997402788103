var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","disable-sort":"","headers":_vm.headers,"items":_vm.$store.getters.getWMSRECEIVINGSkuTableData.data,"options":_vm.options,"server-items-length":_vm.$store.getters.getWMSRECEIVINGSkuTableData.total,"loading":_vm.loading,"mobile-breakpoint":"300","item-class":"text-center","footer-props":{ 'items-per-page-options': [50, 40, 30, 20] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.$store.getters.getWMSRECEIVINGReceivingType !== 3)?{key:"item.sku_component",fn:function({ value }){return [_c('div',{staticClass:"max-w-250px d-flex align-center"},[_c('ShowImage',{attrs:{"item":value}}),_c('div',{staticClass:"d-flex justify-content-start flex-column"},[_c('span',{staticClass:"font-size-lg text-hover-info mb-1"},[_vm._v(_vm._s(value.text1))]),_c('span',{staticClass:"text-dark-25 text-wrap font-size-sm"},[_vm._v(_vm._s(value.text2))])])],1)]}}:null,{key:"item.status",fn:function({ value }){return [_c('span',{staticClass:"text-center badge font-size-sm my-2 py-2 px-3",class:[
        value === 'Created'
          ? 'badge-light-success'
          : value === 'Received'
          ? 'badge-light-primary'
          : 'badge-light-warning',
      ]},[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }