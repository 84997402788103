<template>
  <div class="mt-6">
    <v-data-table
      fixed-header
      light
      dense
      :items="items"
      item-key="asn_number"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item, value }">
        <div class="d-flex align-items">
          <!--    BAQ Receiving    -->
          <template v-if="item.extra_data.detail.actions.receive_baq_asn">
            <button
              class="btn iq-btn poppins mr-2"
              @click="() => newHandleClick(item.asn_number)"
            >
              Receive
            </button>
          </template>

          <template v-else>
            <button
              v-if="item.extra_data.detail.actions.receive"
              class="btn iq-btn poppins mr-2"
              @click="() => newHandleClick(item.asn_number)"
            >
              Receive
            </button>
            <button
              v-if="item.extra_data.detail.actions.blind_receive"
              class="btn iq-btn poppins text-no-wrap mr-2"
              @click="() => newHandleClick(item.asn_number, true)"
            >
              Blind receive
            </button>

            <button
              v-if="item.extra_data.detail.actions.simple_receive"
              class="btn iq-btn poppins text-no-wrap"
              @click="() => newHandleClick(item.asn_number, false, true)"
            >
              Simple receive
            </button>
          </template>
        </div>
      </template>
    </v-data-table>
    <Pagination
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <ReceivingDialog ref="receivingDialog" />
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import ReceivingDialog from "@/own/components/warehouseManagement/receiving/ReceivingDialog";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_BLIND_RECEIVE,
  SET_CAN_DECIDE_RECEIVING_TYPE,
  SET_IS_BAQ_ASN,
  SET_RECEIVE_TYPE,
  SET_SCANNED_CARTONS_FOR_ISS,
  SET_SCANNED_SKUS,
  SET_SIMPLE_RECEIVE,
  SET_SKUS,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/receiving.module";

export default {
  name: "DataTable",
  components: { Pagination, ComponentSelector, ReceivingDialog },
  methods: {
    newHandleClick(asn_number, blindReceive = false, simpleReceive = false) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/show", {
        asn_number: asn_number,
      })
        .then(({ data }) => {
          this.$store.commit(SET_SKUS, data.skus);
          this.$store.commit(SET_BLIND_RECEIVE, blindReceive);
          this.$store.commit(SET_SIMPLE_RECEIVE, simpleReceive);
          this.$store.commit(SET_IS_BAQ_ASN, data.is_baq_asn);
          this.$store.commit(SET_RECEIVE_TYPE, data.receiving_type);
          this.$store.commit(SET_SCANNED_SKUS, []);
          this.$store.commit(SET_SCANNED_CARTONS_FOR_ISS, []);
          this.$refs.receivingDialog.toggleModal(asn_number, data.skus.url);
          this.$store.commit(
            SET_CAN_DECIDE_RECEIVING_TYPE,
            data.can_decide_receiving_type
          );
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getWMSRECEIVINGTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.$forceUpdate();
    },
  },
  computed: {
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getWMSRECEIVINGTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getWMSRECEIVINGTableData?.last_page;
    },

    items: function () {
      let items = [];
      if (this.$store.getters.getWMSRECEIVINGTableData) {
        items = this.$store.getters.getWMSRECEIVINGTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.per_page;
    },

    headers: function () {
      let headers = [];

      if (this.$store.getters.getWMSRECEIVINGTableHeaders.length) {
        headers = this.$store.getters.getWMSRECEIVINGTableHeaders.filter(
          (col) => col.visible === true
        );
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        if (header.value === "action") {
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
  },
};
</script>
